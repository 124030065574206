import { ReactNode } from 'react'
import { ReactComponent as Angular } from '../assets/icons/angular.svg'
import { ReactComponent as CSS } from '../assets/icons/css-3.svg'
import { ReactComponent as Django } from '../assets/icons/dj.svg'
import { ReactComponent as Gatsby } from '../assets/icons/gatsby.svg'
import { ReactComponent as JavaScript } from '../assets/icons/javascript.svg'
import { ReactComponent as Kotlin } from '../assets/icons/kotlin.svg'
import { ReactComponent as Laravel } from '../assets/icons/laravel.svg'
import { ReactComponent as Python } from '../assets/icons/python.svg'
import { ReactComponent as ReactIcon } from '../assets/icons/react.svg'
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg'
import friendsImage from '../assets/images/friends.jpg';
import couplesImage from '../assets/images/couples.jpg';
import youImage from '../assets/images/you.jpg';

const imageWidth = '200px'
const imageHeight = '100px'
const imageBorderRadius = '10px'


const FriendsImageComponent: React.FC = () => {
  return (
    <div>
      <img src={friendsImage}
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}

const CouplesImageComponent: React.FC = () => {
  return (
    <div>
      <img src={couplesImage}
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}

const YouImageComponent: React.FC = () => {
  return (
    <div>
      <img src={youImage}
        style={{ width: imageWidth, height: imageHeight, objectFit: 'cover', borderRadius: imageBorderRadius}}
      />
    </div>
  );
}
type QuizTopic = {
  title: string
  icon?: ReactNode
  image?: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'friends',
    image: <FriendsImageComponent />
  },
  {
    title: 'couples',
    image: <CouplesImageComponent />
  },
  {
    title: 'you',
    image: <YouImageComponent />
  },

]
